<template>
  <div>
    <div class="page-header clear-filter">
      <div class="container">
        <div
          class="row"
          style="
            color: black;
            font-weight: bold;
            text-align: center;
            justify-content: center;
            font-size: large;
          "
        >
          Neu Benutzer hinzufügen
        </div>
        <div class="card">
          <div
            class="tab-content tab-space text-left tab-content-padding text-center"
          >
            <div class="container" style="color: black">
              <div class="row">
                <fg-input
                  class="col-sm-12 col-12"
                  label="Benutzer Name"
                  placeholder="Benutzer Name"
                  v-model="userName"
                  type="text"
                >
                </fg-input>
              </div>
              <div class="row">
                <fg-input
                  class="col-sm-12 col-12"
                  label="Passwort"
                  placeholder="Passwort"
                  v-model="password"
                  type="text"
                >
                </fg-input>
              </div>
              <div class="row">
                <fg-input
                  class="col-sm-12 col-12"
                  label="E-Mail Adresse"
                  placeholder="E-Mail Adresse"
                  v-model="email"
                  type="email"
                >
                </fg-input>
              </div>
              <div class="row">
                <fg-input
                  class="col-sm-12 col-12"
                  label="Notizen"
                  placeholder=""
                  v-model="info"
                  type="text"
                >
                </fg-input>
              </div>
              <div class="row">
                <div class="col">
                  <input
                    type="radio"
                    name="test_id"
                    @change="changeAktuelDate(1)"
                    value="1"
                    :checked="1 == longDate"
                  />
                  1 Monat gültig
                </div>
                <div class="col">
                  <input
                    type="radio"
                    name="test_id"
                    @change="changeAktuelDate(2)"
                    value="2"
                    :checked="2 == longDate"
                  />
                  2 Monaten gültig
                </div>
                <div class="col">
                  <input
                    type="radio"
                    name="test_id"
                    @change="changeAktuelDate(3)"
                    value="3"
                    :checked="3 == longDate"
                  />
                  3 Monaten gültig
                </div>
              </div>
              <div class="row" style="float: right; margin: 40px">
                <button
                  class="btn btn-round btn-question"
                  @click="saveBenutzer()"
                >
                  Speichern
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog style="color: black" />
  </div>
</template>

<script>
import { FormGroupInput as FgInput } from "../../../../components/";

export default {
  components: {
    FgInput,
  },
  data() {
    return {
      baseUrl: "http://" + this.apiBaseHost + ":" + this.apiBasePort,
      userName: null,
      email: null,
      password: null,
      longDate: 2,
      info:null,
    };
  },
  mounted() {},
  methods: {
    changeAktuelDate(value) {
      this.longDate = value;
    },
    saveBenutzer() {
      let date = new Date();
      date.setMonth(date.getMonth() + this.longDate);
      this.$axios
        .post(this.baseUrl + "/auth/local/register", {
          username: this.userName,
          email: this.email,
          password: this.password,
          blockedDate: date,
          provider: "local",
          role: 3,
          blocked: 0,
          created_by: 1,
          updated_by: 1,
          resetPasswordToken: "ekolTaxiSchule",
          info:this.info
        })
        .then((response) => {
          this.$modal.show("dialog", {
            title: "Regiter Info",
            text: "Benutzer wurde erfolgreich hinzugefügt",
            buttons: [
              {
                title: "Schließen",
                handler: () => {
                  this.$modal.hide("dialog");
                },
              },
            ],
          });

          this.userName = null;
          this.password = null;
          this.email = null;
        })
        .catch((error) => {
          this.$modal.show("dialog", {
            title: "Register Error",
            text: error.response.data.message[0].messages[0].message,
            buttons: [
              {
                title: "Schließen",
                handler: () => {
                  this.$modal.hide("dialog");
                },
              },
            ],
          });
        });
    },
  },
};
</script>

<style>
</style>